// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCazErm_CscINQ5IMoinJHFd9Os0YOgNXQ",
  authDomain: "softwarehaus-bc771.firebaseapp.com",
  projectId: "softwarehaus-bc771",
  storageBucket: "softwarehaus-bc771.appspot.com",
  messagingSenderId: "738747176305",
  appId: "1:738747176305:web:61676f7d447504291612d0",
  measurementId: "G-ZR5V8RJNNT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);